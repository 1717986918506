module InventoryControl

open ApiDataTypes
open Thoth.Fetch
open Feliz
open Feliz.Bulma

type private Model = {
    UserSession : UserSession
    Location    : Location.Location
    Inventory   : Product.Inventory [] option
    Control     : Product.InventoryControl []
    Notes       : string
    ErrorMsg    : string option
    Processing  : bool
    Registered  : bool
    History     : Product.ControlLog [] option
}
and private Message =
    | InventoryResponse     of Result<Product.Inventory [], FetchError>
    | HistoryResponse       of Result<Product.ControlLog [], FetchError>
    | Reset
    | ToggleAjour           of int
    | UpdateQuantity        of int * int
    | UpdateNotes           of string
    | DismissError
    | Commit
    | CommitResponse        of Result<GenericMessage, FetchError>


let private init us x = {
    UserSession = us
    Location    = x
    Inventory   = None
    Control     = [||]
    Notes       = ""
    ErrorMsg    = None
    Processing  = false
    Registered  = false
    History     = None
}

let private update model msg =
    match msg with
    | InventoryResponse res ->
        match res with
        | Ok x ->
            let y =
                x
                |> Array.map(fun z -> 
                    {
                        Product     = z.Product
                        Ajour       = false
                        Original    = z.Quantity
                        Quantity    = z.Quantity
                    } : Product.InventoryControl
                )
            {model with Inventory = Some x; Control = y}
        | Error err ->
            let errMsg =
                match err with
                | _ -> "Noe gikk galt. Kunne ikke laste lagerbeholdning."
            {model with ErrorMsg = Some errMsg}
    
    | HistoryResponse res ->
        match res with
        | Ok x -> {model with History = Some x}
        | Error err ->
            let errMsg =
                match err with
                | _ -> "Noe gikk galt. Kunne ikke laste kontrollhistorikk."
            {model with ErrorMsg = Some errMsg}
    
    | Reset ->
        match model.Inventory with
        | Some x ->
            let y =
                x
                |> Array.map(fun z -> 
                    {
                        Product     = z.Product
                        Ajour       = false
                        Original    = z.Quantity
                        Quantity    = z.Quantity
                    } : Product.InventoryControl
                )
            {model with Control = y}
        | None -> model
    
    | ToggleAjour pId ->
        let x =
            model.Control
            |> Array.map(fun y ->
                if y.Product.Id = pId then
                    {y with Ajour = not y.Ajour}
                else y
            )
        {model with Control = x}
    
    | UpdateQuantity (pId, x) ->
        let x =
            model.Control
            |> Array.map(fun y ->
                if y.Product.Id = pId then
                    {y with Quantity = x}
                else y
            )
        {model with Control = x}
    

    
    | UpdateNotes x -> {model with Notes = x}

    | DismissError -> {model with ErrorMsg = None}

    | Commit ->
        {model with Processing = true}
    
    | CommitResponse res ->
        match res with
        | Ok x ->
            if x.Result = Shared.SuccessIndicator then
                let l : Product.ControlLog = {
                    RegisteredBy = sprintf "%s %s" model.UserSession.Firstname model.UserSession.Lastname
                    Registered = System.DateTime.Now.ToString "yyyy-MM-dd"
                }
                let hist =
                    match model.History with
                    | None -> [|l|]
                    | Some h -> Array.append [|l|] h
                {model with Registered = true; ErrorMsg = None; History = Some hist}
            else {model with Processing = false; ErrorMsg = Some x.Message}
        | Error err ->
            let errMsg =
                match err with
                | _ -> "Noe gikk galt. Kunne ikke registrere kontroll."
            {model with Processing = false; ErrorMsg = Some errMsg}

let private fetchInventory locId dispatch =
    promise {
        let requestPath =
            sprintf "/api/inventory/%i" locId
        let! res = Promises.tryGet<Product.Inventory []> requestPath
        dispatch (InventoryResponse res)
    }

let private commit locId (x:Product.InventoryControl []) dispatch =
    promise {
        dispatch Commit
        let requestPath =
            sprintf "/api/control/register/%i" locId
        
        let! res =
            Promises.tryPost<Product.InventoryControl [], GenericMessage>
                requestPath x
        
        dispatch (CommitResponse res)
    }

let private fetchHistory locId dispatch =
    promise {
        let requestPath =
            sprintf "/api/control/%i" locId
        let! res =
            Promises.tryGet<Product.ControlLog []> requestPath
        
        dispatch (HistoryResponse res)
    }


let private drawControlEntry (x: Product.InventoryControl) processing dispatch =
    Html.tr [
        prop.className "product-row"
        prop.children [
            Html.td [
                prop.style [
                    style.width (length.rem 3.2)
                    style.padding (length.px 5)
                ]
                prop.children [
                    ViewHelpers.productImg x.Product.Id 3.1
                ]
            ]
            Html.td [
                Html.span [
                    prop.style [style.fontWeight.bold]
                    prop.text x.Product.Name
                ]
                Html.br []
                Html.span [
                    prop.style [
                        style.fontSize (length.rem 0.8)
                    ]   
                    prop.text (sprintf "Produktnummer: %s" x.Product.ProductNumber)
                ]
            ]
            Html.td [
                prop.style [style.fontStyle.italic; style.fontWeight.bold]
                prop.children [
                    Html.span [
                        prop.style [
                            if not x.Ajour && x.Original <> x.Quantity then
                                style.textDecorationLine.lineThrough
                                style.opacity 0.7
                        ]
                        prop.text (sprintf "%i stk" x.Original)
                    ]
                    if not x.Ajour && x.Original <> x.Quantity then
                        Html.span [
                            prop.style [style.marginLeft (length.px 10)]
                            prop.text (sprintf "%i stk" x.Quantity)
                        ]
                ]
            ]
            Html.td [
                Bulma.icon [
                    Bulma.icon.isMedium
                    prop.style [
                        style.cursor.pointer
                    ]
                    prop.onClick (fun _ -> 
                        if not processing then
                            dispatch (ToggleAjour x.Product.Id))
                    prop.children [
                        Html.i [
                            prop.className (
                                if x.Ajour then "fas fa-check-circle"
                                else "far fa-circle"
                            )
                        ]
                    ]
                ]
            ]
            Html.td [
                if not x.Ajour then
                    Bulma.input.number [
                        Bulma.input.isSmall
                        prop.style [
                            style.maxWidth (length.px 60)
                        ]
                        prop.defaultValue x.Quantity
                        prop.disabled ( x.Ajour || processing )
                        prop.onChange (fun (y:string) ->
                            try
                                let asInt = int y
                                dispatch (UpdateQuantity (x.Product.Id, asInt))
                            with | _ -> ()
                        )
                    ]
            ]
        ]
    ]


let private drawControl model dispatch =
    Bulma.table [
        Bulma.table.isFullWidth
        prop.children [
            Html.thead [
                prop.style [style.fontSize (length.rem 0.8)]
                prop.children [
                    Html.th []
                    Html.th "Produkt"
                    Html.th "Antall"
                    Html.th [
                        prop.style [style.width (length.px 70)]
                        prop.text "Ajour"
                    ]
                    Html.th [
                        prop.style [style.width (length.px 100)]
                        prop.text "Endre"
                    ]
                ]
            ]
            Html.tbody [
                model.Control
                |> Array.map(fun y -> drawControlEntry y model.Processing dispatch)
                |> Fable.React.Helpers.ofArray
            ]
        ]
    ]

let private drawControlHistory model dispatch =
    Html.div [
        prop.style [
            style.marginTop (length.px 20)
        ]
        prop.children [
            Html.h5 [
                prop.style [
                    style.color "#fff"
                    style.marginBottom (length.px 3)
                    style.marginLeft (length.px 15)
                ]
                prop.className "title is-5"
                prop.text "Siste gjennomførte kontroller"
            ]

            match model.History with
            | None -> ViewHelpers.loadingIndicatorSmall ()
            | Some x ->

                Bulma.table [
                    prop.style [style.marginBottom 0]
                    prop.children [
                        Html.thead []
                        Html.tbody [
                            x
                            |> Array.map(fun y ->
                                Html.tr [
                                    prop.style [style.fontSize (length.rem 0.8)]
                                    prop.className "notification-row"
                                    prop.children [
                                        Html.td (Utils.fromDateToLocalDate y.Registered)
                                        Html.td y.RegisteredBy
                                    ]
                                ]
                            )
                            |> Fable.React.Helpers.ofArray

                            if x.Length = 0 then
                                Html.tr [
                                    prop.className "notification-row"
                                    prop.children [
                                        Html.td []
                                        Html.td "Ingen registrerte kontroller."
                                    ]
                                ]
                        ]
                    ]
                ]
        ]
    ]

let private view model dispatch =
    Html.div [
        Html.div [
            prop.style [
                style.textAlign.right
            ]
            prop.children [
                Html.span [
                    prop.style [style.color "#fff"; style.fontWeight.bold]
                    prop.text "Lagerkontroll"
                ]
            ]
        ]
        Html.div [
            prop.style [
                style.custom ("border", "solid 1px #4d8398")
                style.borderRadius 5
                style.padding (length.vw 1)
                style.backgroundColor "#e0f1f1"
                style.overflowY.scroll
                style.maxHeight (length.vh 70)
            ]
            prop.children [
                match model.Inventory with
                | None -> ViewHelpers.loadingIndicatorSmall ()
                | Some _ ->
                    drawControl model dispatch
            ]
        ]
        match model.ErrorMsg with
        | None -> ()
        | Some err -> ViewHelpers.errorMsg err (fun _ -> dispatch DismissError)
        
        Html.div [
            prop.style [
                style.marginTop (length.px 10)
                style.display.flex
                style.justifyContent.spaceBetween
            ]
            prop.children [
                if not model.Registered then
                    Bulma.button.button [
                        Bulma.button.isSmall
                        if model.Processing then
                            Bulma.button.isLoading
                        prop.style [
                        ]
                        prop.onClick (fun _ -> 
                            if not model.Processing then dispatch Reset)
                        prop.text "Nullstill"
                    ]

                    Bulma.button.button [
                        Bulma.button.isSmall
                        if model.Processing then
                            Bulma.button.isLoading
                        prop.style [

                        ]
                        prop.onClick (fun _ ->
                            if not model.Processing then
                                commit model.Location.Id model.Control dispatch
                                |> Promise.start
                        )
                        prop.text "Registrer kontroll"
                    ]
                else
                    Html.span [
                        prop.style [
                            style.color "#fff"
                            style.fontWeight.bold
                        ]
                        prop.text "Kontroll er registrert."
                    ]
            ]
        ] 

        drawControlHistory model dispatch
    ]


let inventoryControl session location =
    React.functionComponent ("inventory-control", (fun (props : {|us : UserSession; loc : Location.Location|}) -> 
        let initialModel = init props.us props.loc
        let model, dispatch = React.useReducer(update, initialModel)

        React.useEffect((fun _ ->
            fetchInventory props.loc.Id dispatch
            |> Promise.start
            fetchHistory props.loc.Id dispatch
            |> Promise.start
        ), [||])

        view model dispatch
    )) {|us = session; loc = location|}