module Startpage

open ApiDataTypes
open Feliz
open Feliz.Bulma
open Thoth.Fetch
open Feliz.Recharts

type private Model = {
    Location        : Location.Location
    Notifications   : Product.Notification [] option
    ErrorMsg        : string option
    Usage           : Product.Usage [] option
}
and private Message =
    | NotificationsResponse of Result<Product.Notification [], FetchError>
    | UsageResponse         of Result<Product.Usage [], FetchError>

let private init x = {
    Location        = x
    Notifications   = None
    ErrorMsg        = None
    Usage           = None
}

let private update model msg =
    match msg with
    | NotificationsResponse res ->
        match res with
        | Ok x -> {model with Notifications = Some x}
        | Error err ->
            let errMsg =
                match err with
                | _ -> (sprintf "Noe gikk galt. Kunne ikke laste merknader for %s." model.Location.Name)
            {model with ErrorMsg = Some errMsg}
    
    | UsageResponse res ->
        match res with
        | Ok x -> {model with Usage = Some x}
        | Error err ->
            let errMsg =
                match err with
                | _ -> "Noe gikk galt. Kunne ikke laste forbruk."
            {model with ErrorMsg = Some errMsg}


let private fetchNotifications locId dispatch =
    promise {
        let requestPath =
            sprintf "/api/notification/%i" locId
        let! res = Promises.tryGet<Product.Notification []> requestPath
        dispatch (NotificationsResponse res)
    }

let private fetchUsage locId dispatch =
    promise {
        let requestPath =
            sprintf "/api/inventory/usage/%i" locId

        let! res = Promises.tryGet<Product.Usage []> requestPath
        dispatch (UsageResponse res)
    }


let private drawNotifications model dispatch =
    Html.div [
        prop.style [
            style.marginTop (length.px 20)
        ]
        prop.children [
            Html.h5 [
                prop.style [
                    style.color "#fff"
                    style.marginBottom (length.px 3)
                    style.marginLeft (length.px 15)
                ]
                prop.className "title is-5"
                prop.text "Merknader"
            ]

            Html.div [
                match model.Notifications with
                | None ->
                    ViewHelpers.loadingIndicatorSmall()
                
                | Some x ->

                    Bulma.table [
                        prop.style [style.marginBottom 0]
                        prop.children [
                            Html.thead []
                            Html.tbody [
                                x
                                |> Array.map(fun y ->
                                    Html.tr [
                                        prop.className "notification-row"
                                        prop.children [
                                            Html.td [
                                                prop.children [
                                                    match y.Type with
                                                    | Shared.NotificationType.BelowMin ->
                                                        Html.i [prop.className "fas fa-arrow-alt-circle-down fa-lg"]
                                                    | Shared.NotificationType.Expires ->
                                                        Html.i [prop.className "fas fa-clock fa-lg"]
                                                    | _ -> Html.none
                                                ]
                                            ]
                                            Html.td y.Text
                                        ]
                                    ]
                                )
                                |> Fable.React.Helpers.ofArray
                            ]
                        ]
                    ]
            ]
        ]
    ]

type DataPoint = {``uke``: string; ``forbruk``:int; ``mottak``: int; }

let private drawUsage model dispatch =
    Html.div [
        prop.style [
            style.marginTop (length.px 20)
            style.minHeight (length.px 300)
        ]
        prop.children [
            Html.div [
                prop.style [
                    style.textAlign.left
                ]
                prop.children [
                    Html.span [
                        prop.style [style.color "#fff"; style.fontWeight.bold]
                        prop.text "Ukentlig forbruk og leveranse av helsemateriell"
                    ]
                ]
            ]
            match model.Usage with
            | None -> ViewHelpers.loadingIndicatorMedium ()
            | Some x ->

                Html.div [
                    prop.style [
                        style.backgroundColor "#fff"
                        style.paddingTop (length.px 20)
                        style.paddingBottom (length.px 15)
                        style.maxWidth (length.px 600)
                        style.borderRadius 10
                        style.paddingRight (length.px 5)
                    ]
                    prop.children [
                        let data =
                            x
                            |> Array.sortBy(fun y -> y.Year, y.Week)
                            |> Array.map (fun y -> 
                                {
                                    uke = string y.Week
                                    forbruk = - y.Usage
                                    mottak = y.Added
                                }
                            )
                        Recharts.responsiveContainer [
                            responsiveContainer.height (length.percent 100)
                            responsiveContainer.width (length.percent 100)
                            responsiveContainer.minHeight (length.px 250)
                            responsiveContainer.chart (
                                Recharts.barChart [
                                    barChart.data data
                                    barChart.children [
                                        Recharts.cartesianGrid [ cartesianGrid.strokeDasharray(3, 3) ]
                                        Recharts.xAxis [ 
                                            xAxis.dataKey (fun point -> point.uke)
                                            Recharts.Interop.mkXAxisAttr "tick" {| fill = "#131313"|}
                                            Interop.mkXAxisAttr "stroke" "#131313"
                                            
                                        ]
                                        Recharts.yAxis [
                                            Interop.mkYAxisAttr "tick" {|fill="#131313"|}
                                            Interop.mkYAxisAttr "stroke" "#131313"
                                        ]
                                        Recharts.tooltip [ ]
                                        //Recharts.legend [
                                            
                                        //]

                                        Recharts.referenceLine [
                                            referenceLine.y 0
                                            referenceLine.stroke "#131313"
                                        ]

                                        Recharts.bar [
                                            bar.dataKey (fun point -> point.mottak)
                                            bar.fill "#26c74c"
                                        ]

                                        Recharts.bar [
                                            bar.dataKey (fun point -> point.forbruk)
                                            bar.fill "#e86e2c"
                                        ]

                                    ]
                                ]
                            )
                        ]

                        Html.div [
                            prop.style [style.display.flex; style.justifyContent.center]
                            prop.children [
                                Html.div [
                                    prop.style [
                                        style.display.flex
                                        style.marginRight (length.px 10)
                                    ]
                                    prop.children [
                                        Bulma.icon [
                                            Html.i [
                                                prop.style [style.color "#26c74c"]
                                                prop.className "fas fa-circle"
                                            ]
                                        ]

                                        Html.span "Mottak"
                                    ]
                                ]
                                Html.div [
                                    Bulma.icon [
                                        Html.i [
                                            prop.style [style.color "#e86e2c"]
                                            prop.className "fas fa-circle"
                                        ]
                                    ]

                                    Html.span "Forbruk"
                                ]
                            ]
                        ]
                    ]
                ]
        ]
    ]

let private view model dispatch =
    Html.div [
        drawUsage model dispatch
        drawNotifications model dispatch
    ]


let startpage location =
    React.functionComponent ("startpage", (fun (props : {|loc : Location.Location|}) -> 
        let initialModel = init props.loc
        let model, dispatch = React.useReducer(update, initialModel)

        React.useEffect((fun _ ->
            fetchNotifications props.loc.Id dispatch
            |> Promise.start

            fetchUsage props.loc.Id dispatch
            |> Promise.start
        ), [||])

        view model dispatch
    )) {| loc = location|}