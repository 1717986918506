module Admin

open Elmish
open Thoth.Fetch
open Feliz
open Feliz.Bulma
open ApiDataTypes

type FetchError = Thoth.Fetch.FetchError
type GenericResult = Result<GenericMessage, FetchError>

type EditType =
    | Create
    | Update

let locale = Locale.Locale

module LocationForm =
    open Fable.Reaction
    open FSharp.Control

    type private Model = {
        Location        : Location.Location
        EditType        : EditType
        OnClose         : unit -> unit
        Processing      : bool
        ErrorMsg        : string option
    }
    and private Message =
        | UpdateName        of string
        | DismissError
        | Commit            of Location.Location
        | Remove            of Location.Location
        | CommitResponse    of GenericResult

    let private init (x: Location.Location option) onClose =
        {
            Location =
                match x with
                | Some y -> y
                | None ->
                    {
                        Id = -1
                        Name = ""
                    }
            EditType    = if x.IsSome then Update else Create
            OnClose     = onClose
            Processing  = false
            ErrorMsg    = None
        }

    let private handleCommit model (x : Location.Location) =
        if x.Name.Length > 0 then
            { model with Processing = true }
        else
            { model with ErrorMsg = Some locale.group.``invalid-group`` }

    let private handleCommitResponse model (res : GenericResult) =
        match res with
        | Ok x ->
            if x.Result = Shared.SuccessIndicator then
                model.OnClose ()
                model
            else
                { model with ErrorMsg = Some x.Message }
        | Error x ->
            Utils.checkError x
            { model with
                ErrorMsg = Some locale.fields.``action-error`` }

    let private update model msg =
        match msg with
        | UpdateName x -> { model with Location = { model.Location with Name = x } }
        | DismissError -> { model with ErrorMsg = None }
        | Commit x -> handleCommit model x
        | Remove _ -> { model with Processing = true }
        | CommitResponse res -> handleCommitResponse model res

    let private addOrUpdate (x : Location.Location) =
        let p =
            Promises.tryPost<Location.Location, GenericMessage>
                "/api/location/add" x
        AsyncRx.ofPromise p
        |> AsyncRx.map CommitResponse

    let private remove (x : Location.Location) =
        let p =
            Promises.tryPost<string, GenericMessage>
                (sprintf "/api/location/remove/%i" x.Id) ""
        AsyncRx.ofPromise p
        |> AsyncRx.map CommitResponse

    let private stream model msgs =
        let commit =
            msgs
            |> AsyncRx.choose (
                function
                | Commit x when (x.Name.Length > 0) -> Some x
                | _ -> None)
            |> AsyncRx.flatMap addOrUpdate
        let remove =
            msgs
            |> AsyncRx.choose (function | Remove x -> Some x | _ -> None)
            |> AsyncRx.flatMap remove
        AsyncRx.mergeSeq [
            msgs
            commit
            remove
        ] |> AsyncRx.tag "msgs"

    let private view model dispatch =
        let nameInput =
            Bulma.field.div [
                Bulma.label [ Html.text locale.fields.name ]
                Bulma.input.text [
                    prop.onChange (UpdateName >> dispatch)
                    prop.defaultValue model.Location.Name
                    prop.placeholder locale.fields.name
                ]
            ]
        let addButton =
            Bulma.button.button [
                Bulma.color.isInfo
                Bulma.button.isSmall
                if model.Processing then Bulma.button.isLoading
                prop.style [ style.marginRight (length.px 20)]
                prop.onClick (fun _ ->
                    if not model.Processing then
                        dispatch (Commit model.Location)
                )
                prop.children [
                    Bulma.icon [
                        Bulma.icon.isSmall
                        Bulma.icon.isLeft
                        prop.children [
                            Html.i [ prop.className
                                (if model.EditType = Create then
                                    "fas fa-plus"
                                 else "fas fa-sync")
                            ]
                        ]
                    ]
                    Html.span [ Html.text
                        (if model.EditType = Create then
                            locale.fields.add
                        else
                            locale.fields.update)
                    ]
                ]
            ]
        let cancelButton =
            Bulma.button.button [
                Bulma.button.isSmall
                if model.Processing then Bulma.button.isLoading
                prop.style [ style.marginRight (length.px 20) ]
                prop.onClick (fun _ ->
                    if not model.Processing then
                        model.OnClose ()
                )
                prop.children [
                    Html.text locale.fields.cancel
                ]
            ]
        let deleteButton =
            Bulma.button.button [
                Bulma.color.isDanger
                Bulma.button.isSmall
                if model.Processing then Bulma.button.isLoading
                prop.onClick (fun _ ->
                    if not model.Processing then
                        dispatch (Remove model.Location)
                )
                prop.children [
                    Bulma.icon [
                        Bulma.icon.isSmall
                        Bulma.icon.isLeft
                        prop.children [
                            Html.i [ prop.className "fas fa-trash" ]
                        ]
                    ]
                    Html.span [ Html.text locale.fields.delete ]
                ]
            ]
        ViewHelpers.modalBox (fun _ -> model.OnClose()) [
            nameInput
            addButton
            cancelButton
            if model.EditType = Update then
                deleteButton
            match model.ErrorMsg with
            | None -> ()
            | Some errMsg ->
                ViewHelpers.errorMsg errMsg (fun _ -> dispatch DismissError)
        ]
    let groupForm x =
        React.functionComponent ("GroupForm",
           fun (props : {| loc : Location.Location option; close : unit -> unit |}) ->
                let initialModel = init props.loc props.close
                let model =
                    Fable.React.HookBindings.Hooks.useReducer (update , initialModel)
                let dispatch, _ =
                   Reaction.useStatefulStream (model.current, model.update, stream)
                view model.current dispatch
        ) x

module UserForm =
    open Fable.Reaction
    open FSharp.Control

    type private Model = {
        User            : User.UserForm
        EditType        : EditType
        OnClose         : unit -> unit
        Processing      : bool
        ErrorMsg        : string option
    }

    type  private Message =
        | UpdateUsername    of string
        | UpdateEmail       of string
        | UpdateFirstname   of string
        | UpdateLastname    of string
        | UpdatePassword    of string
        | UpdateIsAdmin     of bool
        | DismissError
        | Commit            of User.UserForm * bool
        | Remove            of User.UserForm
        | CommitResponse    of GenericResult

    let private init (x : User.User option) onClose =
        {
            User =
                match x with
                | Some usr -> {
                        Id              = usr.Id
                        Username        = usr.Username
                        Email           = usr.Email
                        Firstname       = usr.Firstname
                        Lastname        = usr.Lastname
                        Password        = ""
                        IsAdmin         = usr.IsAdmin
                    }
                | None -> {
                        Id              = -1
                        Username        = ""
                        Email           = ""
                        Firstname       = ""
                        Lastname        = ""
                        Password        = ""
                        IsAdmin         = false
                    }
            EditType    = if x.IsSome then Update else Create
            OnClose     = onClose
            Processing  = false
            ErrorMsg    = None
        }

    let private handleCommit model (x : User.UserForm) =
        let validEmail      = x.Email.Length    > 0
        let validUsername   = x.Username.Length > 0
        let validFirstname  = x.Firstname.Length > 0
        let validLastname   = x.Lastname.Length > 0
        let validPassword   = (x.Password.Length > 0) || model.EditType = Update
        let allValid =
            validEmail && validUsername && validFirstname &&
            validLastname && validPassword
        if allValid             then { model with Processing = true }
        elif not validUsername  then { model with ErrorMsg = Some locale.fields.``invalid-username`` }
        elif not validEmail     then { model with ErrorMsg = Some locale.fields.``invalid-email`` }
        elif not validFirstname then { model with ErrorMsg = Some locale.fields.``invalid-firstname`` }
        elif not validLastname  then { model with ErrorMsg = Some locale.fields.``invalid-lastname`` }
        else { model with ErrorMsg = Some locale.fields.``invalid-pwd`` }

    let private handleCommitResponse model (res : GenericResult) =
        match res with
        | Ok x ->
            if x.Result = Shared.SuccessIndicator then
                model.OnClose()
                model
            else
                { model with ErrorMsg = Some x.Message }
        | Error x ->
            Utils.checkError x
            { model with
                ErrorMsg = Some locale.fields.``action-error`` }

    let private update model msg =
        match msg with
        | UpdateUsername x  -> { model with User = { model.User with Username = x   } }
        | UpdateEmail x     -> { model with User = { model.User with Email = x      } }
        | UpdateFirstname x -> { model with User = { model.User with Firstname = x  } }
        | UpdateLastname x  -> { model with User = { model.User with Lastname = x   } }
        | UpdatePassword x  -> { model with User = { model.User with Password = x   } }
        | UpdateIsAdmin x   -> { model with User = { model.User with IsAdmin = x    } }
        | DismissError      -> { model with ErrorMsg = None }
        | Commit (x, _)   -> handleCommit model x
        | Remove _ -> { model with Processing = true }
        | CommitResponse res -> handleCommitResponse model res

    let private addOrUpdate (x : User.UserForm) =
        let p = Promises.tryPost<User.UserForm, GenericMessage> "/api/user/add" x
        AsyncRx.ofPromise p
        |> AsyncRx.map CommitResponse

    let private remove (x : User.UserForm) =
        let p = Promises.tryPost<string, GenericMessage> (sprintf "/api/user/remove/%i" x.Id) ""
        AsyncRx.ofPromise p
        |> AsyncRx.map CommitResponse

    let private stream model msgs =
        let validate (x : User.UserForm) validatePassword =
            let validPass   = if validatePassword then x.Password.Length > 0 else true
            let validEmail  = x.Email.Length > 0 && x.Username.Length > 0
            let validName   = x.Firstname.Length > 0 && x.Lastname.Length > 0
            if validEmail && validName && validPass then Some x
            else None
        let commit =
            msgs
            |> AsyncRx.choose (
                function
                | Commit (x, validatePassword) -> validate x validatePassword
                | _ -> None
            )
            |> AsyncRx.flatMap addOrUpdate
        let remove =
            msgs
            |> AsyncRx.choose (function | Remove x -> Some x | _ -> None)
            |> AsyncRx.flatMap remove
        AsyncRx.mergeSeq [
            msgs
            commit
            remove
        ] |> AsyncRx.tag "msgs"

    let private view model dispatch =
        let label' (txt : string) = Bulma.label [
                prop.style [ style.fontSize (length.rem 0.8)]
                prop.children [ Html.text txt ]
            ]
        ViewHelpers.modalBox (fun _ -> model.OnClose()) [
            Bulma.field.div [
                label' locale.fields.username
                Bulma.input.text [
                    Bulma.input.isSmall
                    prop.onChange (UpdateUsername >> dispatch)
                    prop.defaultValue model.User.Username
                    prop.placeholder locale.fields.username
                ]
            ]
            Bulma.field.div [
                label' locale.fields.email
                Bulma.input.text [
                    Bulma.input.isSmall
                    prop.onChange (UpdateEmail >> dispatch)
                    prop.defaultValue model.User.Email
                    prop.placeholder locale.fields.email
                ]
            ]
            Bulma.field.div [
                label' locale.fields.firstname
                Bulma.input.text [
                    Bulma.input.isSmall
                    prop.onChange (UpdateFirstname >> dispatch)
                    prop.defaultValue model.User.Firstname
                    prop.placeholder locale.fields.firstname
                ]
            ]
            Bulma.field.div [
                label' locale.fields.lastname
                Bulma.input.text [
                    Bulma.input.isSmall
                    prop.onChange (UpdateLastname >> dispatch)
                    prop.defaultValue model.User.Lastname
                    prop.placeholder locale.fields.lastname
                ]
            ]
            if model.EditType = Create then
                Bulma.field.div [
                    label' locale.fields.password
                    Bulma.input.password [
                        Bulma.input.isSmall
                        prop.onChange (UpdatePassword >> dispatch)
                        prop.defaultValue model.User.Password
                        prop.placeholder locale.fields.password
                    ]
                ]
            Bulma.field.div [
                Bulma.label [
                    prop.style [ style.fontSize (length.rem 0.8) ]
                    prop.children [
                        Bulma.input.checkbox [
                            prop.style [ style.marginRight (length.px 5) ]
                            prop.isChecked model.User.IsAdmin
                            prop.onChange (UpdateIsAdmin >> dispatch)
                        ]
                        Html.text locale.admin.admin
                    ]
                ]
            ]
            Bulma.button.button [
                Bulma.color.isInfo
                Bulma.button.isSmall
                if model.Processing then Bulma.button.isLoading
                prop.style [ style.marginRight (length.px 20)]
                prop.onClick (fun _ ->
                    if not model.Processing then
                        dispatch (Commit (model.User, model.EditType = Create))
                )
                prop.children [
                    Bulma.icon [
                        Bulma.icon.isSmall
                        Bulma.icon.isLeft
                        prop.children [
                            Html.i [
                                prop.className (
                                    if model.EditType = Create then
                                        "fas fa-plus"
                                    else
                                        "fas fa-sync"
                                )
                            ]
                        ]
                    ]
                    Html.span [
                        Html.text (
                            if model.EditType = Create then
                                locale.fields.add
                            else
                                locale.fields.update
                        )
                    ]
                ]
            ]
            Bulma.button.button [
                Bulma.button.isSmall
                if model.Processing then Bulma.button.isLoading
                prop.style [ style.marginRight (length.px 20) ]
                prop.onClick(fun _ -> if not model.Processing then model.OnClose ())
                prop.text locale.fields.cancel
            ]
            if model.EditType = Update then
                Bulma.button.button [
                    Bulma.color.isDanger
                    Bulma.button.isSmall
                    if model.Processing then Bulma.button.isLoading
                    prop.onClick (fun _ ->
                        if not model.Processing then
                            dispatch (Remove model.User)
                    )
                    prop.children [
                        Bulma.icon [
                            Bulma.icon.isSmall
                            Bulma.icon.isLeft
                            prop.children [
                                Html.i [ prop.className "fas fa-trash" ]
                            ]
                        ]
                        Html.span locale.fields.remove
                    ]
                ]
            match model.ErrorMsg with
            | None -> ()
            | Some errMsg ->
                ViewHelpers.errorMsg errMsg (fun _ -> dispatch DismissError)
        ]

    let userForm x =
        React.functionComponent ("UserForm",
            fun (props : {| user : User.User option; close : unit -> unit |}) ->
                let initialModel = init props.user props.close
                let model = Fable.React.HookBindings.Hooks.useReducer (update , initialModel)
                let dispatch, _ =
                    Reaction.useStatefulStream(model.current, model.update, stream)
                view model.current dispatch
            ) x

type Model = {
    UserSession     : UserSession
    Users           : User.User [] option
    ErrorMsg        : string option
    SelectedUser    : User.User option
    DisplayUserForm : bool
}

type Message =
    | UsersResponse         of Result<User.User [], FetchError>
    | SelectUser            of User.User
    | ToggleUserForm
    | CloseForms

let init userSession =
    let mdl = {
        UserSession     = userSession
        Users           = None
        ErrorMsg        = None
        SelectedUser    = None
        DisplayUserForm = false
    }
    let u () = Promises.tryGet<User.User []> "/api/user/all"
    let cmds = Cmd.batch [
        Cmd.OfPromise.perform u () UsersResponse
    ]
    mdl, cmds

let update msg model =
    match msg with
    | UsersResponse res ->
        match res with
        | Ok x    ->
            { model with Users = Some x }, Cmd.none
        | Error x ->
            Utils.checkError x
            { model with ErrorMsg = Some locale.admin.``users-load-error`` }, Cmd.none
    | SelectUser x ->
        { model with SelectedUser = Some x }, Cmd.none
    | ToggleUserForm ->
        { model with DisplayUserForm = not model.DisplayUserForm }, Cmd.none
    | CloseForms ->
        let u () = Promises.tryGet<User.User []> "/api/user/all"
        let cmds = Cmd.batch [
            Cmd.OfPromise.perform u () UsersResponse
        ]
        { model with SelectedUser = None; DisplayUserForm = false }, cmds

let userList model dispatch =
    let userList users =
        Bulma.table [
            Bulma.table.isFullWidth
            Bulma.table.isHoverable
            prop.children [
                Html.thead [
                    Html.tr [
                        Html.th locale.fields.username
                        Html.th locale.fields.email
                        Html.th locale.admin.admin
                    ]
                ]
                Html.tbody [
                    users
                    |> Array.map(fun x ->
                        Html.tr [
                            prop.style [ style.cursor.pointer ]
                            prop.onClick(fun _ -> dispatch (SelectUser x))
                            prop.children [
                                Html.td [ Html.text x.Username                          ]
                                Html.td [ Html.text x.Email                             ]
                                Html.td [
                                    Html.text (
                                        if x.IsAdmin then
                                            locale.fields.yes
                                        else
                                            locale.fields.no
                                    )
                                ]
                            ]
                        ]
                    )
                    |> Fable.React.Helpers.ofArray
                ]
            ]
        ]
    Html.div [
        prop.style [ style.marginBottom (length.px 20) ]
        prop.children [
            Html.h3 [
                prop.className "title is-3"
                prop.text locale.admin.users
            ]
            match model.Users with
            | None -> Bulma.button.button [ Bulma.button.isLoading ]
            | Some users ->
                userList users
                Bulma.button.button [
                    Bulma.button.isSmall
                    prop.onClick(fun _ -> dispatch ToggleUserForm)
                    prop.children [ Html.i [ prop.className "fas fa-plus" ] ]
                ]
                match model.SelectedUser with
                | None -> ()
                | Some x ->
                    UserForm.userForm
                        {|
                            close = (fun _ -> dispatch CloseForms)
                            user = Some x
                        |}
                if model.DisplayUserForm then
                    UserForm.userForm
                        {|
                            close = (fun _ -> dispatch CloseForms)
                            user = None
                        |}
        ]
    ]

let view model dispatch =
    Bulma.column [
        Bulma.column.isHalf
        Bulma.column.isOffsetOneQuarter
        prop.onKeyUp(fun ke ->
            if ke.key = "Escape" then
                dispatch CloseForms
        )
        prop.children [
            userList model dispatch
        ]
    ]
