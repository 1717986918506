module App

open System
open Elmish
open Thoth.Json
open Thoth.Fetch
open Shared
open Feliz
open Feliz.Bulma
open Feliz.Router

type SubModel =
    | Overview
    | LoginModel    of Login.Model
    | AdminModel    of Admin.Model
    | AccountModel  of Account.Model

type Model = {
    CurrentUrl  : string list
    UserSession : ApiDataTypes.UserSession option
    SubMdl      : SubModel
}

type Message =
    | Authenticate      of Result<bool, FetchError>
    | Exn               of Exception
    | LoginMessage      of Login.Message
    | AdminMessage      of Admin.Message
    | AccountMessage    of Account.Message
    | Logout
    | GotoOverview
    //| GotoAdmin
    | GotoAccount
    | UrlChanged        of string list

let locale = Locale.Locale

let init () =
    match LocalStorage.tryGetSession () with
    | Ok us ->
        let p () =
            Promises.tryPost<string, bool>
                "/api/auth/validate" ""
        let a () =
            Promises.tryPost<string, bool>
                "/api/auth/admin/validate" ""
        let cmds = Cmd.batch [
            Cmd.OfPromise.perform p () Authenticate
        ]
        { CurrentUrl = []; UserSession = Some us; SubMdl = Overview }, cmds
    | _ ->
        let mdl, cmd = Login.init ()
        let cmds = Cmd.batch [
            Cmd.map LoginMessage cmd
        ]
        {
            CurrentUrl = []
            UserSession = None
            SubMdl = LoginModel mdl
        }, cmds

let private handleAuthenticate model res =
    match res with
    | Ok x when x -> model, Cmd.none
    | _ ->
        LocalStorage.deleteSession ()
        let mdl, cmd = Login.init ()
        { model with SubMdl = LoginModel mdl }, Cmd.map LoginMessage cmd

let private handleLoginMessage model lmsg =
    match model.SubMdl with
    | LoginModel lmdl ->
        match lmsg with
        | Login.Message.Success uSession ->
            { model with UserSession = Some uSession; SubMdl = Overview }, Cmd.none
        | _ ->
            let mdl, cmd = Login.update lmsg lmdl
            { model with SubMdl = LoginModel mdl }, Cmd.map LoginMessage cmd
    | _ -> model, Cmd.none

let private handleAccountMessage model amsg =
    match model.SubMdl with
    | AccountModel amdl ->
        let mdl, cmd = Account.update amsg amdl
        { model with SubMdl = AccountModel mdl }, Cmd.none
    | _ -> model, Cmd.none

let private handleAdminMessage model amsg =
    match model.SubMdl with
    | AdminModel amdl ->
        let mdl, cmd = Admin.update amsg amdl
        { model with SubMdl = AdminModel mdl }, Cmd.map AdminMessage cmd
    | _ -> model, Cmd.none

let private handleLogout model =
    LocalStorage.deleteSession ()
    let mdl, cmd = Login.init ()
    { model with
        UserSession = None
        SubMdl = LoginModel mdl
    }, Cmd.map LoginMessage cmd

let private handleGotoOverview model =
    match model.UserSession with
    | Some us -> { model with SubMdl = Overview }, Cmd.none
    | None ->
        LocalStorage.deleteSession ()
        let mdl, cmd = Login.init ()
        { model with SubMdl = LoginModel mdl }, Cmd.map LoginMessage cmd
(*
let private handleGotoAdmin model =
    match model.UserSession with
    | Some us when us.IsAdmin ->
        let mdl, cmd = Admin.init us
        { model with SubMdl = AdminModel mdl }, Cmd.map AdminMessage cmd
    | _ -> model, Cmd.none
*)

let private handleGotoAccount model =
    match model.UserSession with
    | Some us ->
        let mdl, cmd = Account.init us
        { model with SubMdl = AccountModel mdl }, Cmd.map AccountMessage cmd
    | None ->
        LocalStorage.deleteSession ()
        let mdl, cmd = Login.init ()
        { model with SubMdl = LoginModel mdl }, Cmd.map LoginMessage cmd

let private handleUrlChanged model path =
    match path with
    | ["passwordreset"; token ] -> model, Cmd.none
    | _ -> model, Cmd.none

let update (msg: Message) (model : Model) =
    match msg with
    | Authenticate res -> handleAuthenticate model res
    | LoginMessage lmsg -> handleLoginMessage model lmsg
    | AdminMessage amsg -> handleAdminMessage model amsg
    | AccountMessage amsg -> handleAccountMessage model amsg
    | Logout -> handleLogout model
    | GotoOverview -> handleGotoOverview model
    //| GotoAdmin -> handleGotoAdmin model
    | GotoAccount -> handleGotoAccount model
    | UrlChanged path -> handleUrlChanged model path
    | Exn exn -> printfn "EXCEPTION: %A" exn; model, Cmd.none

let navBar model dispatch =
    let navLogo =
            Bulma.navbarItem.div [
                prop.style [ style.cursor.pointer ]
                prop.onClick(fun _ -> dispatch GotoOverview)
                prop.children [
                    //Html.img [ prop.src "/img/logo.png" ]
                    //Html.span "North Aid"
                ]
            ]
    let navBurger updateActive active =
            Bulma.navbarBurger [
                prop.onClick(fun _ -> updateActive (not active))
                prop.children [
                    Html.span []
                    Html.span []
                    Html.span []
                ]
            ]
    let navMenu ddActive updateDD active =
        Bulma.navbarEnd.div [
            match model.UserSession with
            | None -> ()
            | Some us ->
                (*if us.IsAdmin then
                    Bulma.navbarItem.div [
                        prop.style [ style.cursor.pointer ]
                        prop.onClick(fun _ -> dispatch GotoAdmin)
                        prop.children [ Html.i [ prop.className "fas fa-cog" ] ]
                    ]
                *)
                Bulma.navbarItem.div [
                    Bulma.navbarItem.hasDropdown
                    Bulma.navbarItem.isHoverable
                    prop.style [ style.zIndex 10000 ]
                    if ddActive then Bulma.navbarItem.isActive
                    prop.onClick(fun _ -> updateDD (not ddActive))
                    prop.children [
                        Bulma.navbarLink.div [
                            Html.text us.Firstname
                        ]
                        Bulma.navbarDropdown.div [
                            (*Bulma.navbarItem.div [
                                prop.onClick(fun _ -> dispatch GotoAccount)
                                prop.style [ style.cursor.pointer]
                                prop.text locale.account.account
                            ]*)
                            //Bulma.navbarDivider []
                            Bulma.navbarItem.div [
                                prop.onClick(fun _ -> dispatch Logout)
                                prop.style [ style.cursor.pointer]
                                prop.text locale.fields.logout
                            ]
                        ]
                    ]
                ]
        ]
    let plays = Option.defaultValue "none"
    React.functionComponent("NavBar", fun (props : {| isActive : bool; dropdownActive : bool |}) ->
        let active, updateActive = React.useState(props.isActive)
        let ddActive, updateDD = React.useState(props.dropdownActive)
        Bulma.navbar [
            //color.isLight
            prop.children [
                Bulma.navbarBrand.div [
                    navLogo
                    navBurger updateActive active
                ]
                Bulma.navbarMenu [
                    Bulma.navbarMenu.isFixedTop
                    if active then Bulma.navbarMenu.isActive
                    prop.children [
                        Bulma.navbarStart.div []
                        navMenu ddActive updateDD active
                    ]
                ]
            ]
        ]
    ) {| isActive = false; dropdownActive = false |}

let homeView =
    Html.div [
        prop.style [
            style.display.flex
            style.width (length.vw 100)
            style.height (length.vh 100)
            style.justifyContent.center
            style.alignItems.center
        ]
        prop.children [
            Html.h3 [
                prop.className "title is-3"
                prop.text "Develop me"
            ]
        ]
    ]

let render (model: Model) (dispatch: Message -> unit) =
    Html.div [
        Router.router [
            Router.onUrlChanged (UrlChanged >> dispatch)
        ]
        match model.SubMdl with
        | LoginModel _ -> ()
        | _ -> navBar model dispatch
        match model.SubMdl with
        | LoginModel mdl    -> Login.view mdl (LoginMessage >> dispatch)
        | AdminModel mdl    -> Admin.view mdl (AdminMessage >> dispatch)
        | AccountModel mdl  -> Account.view mdl (AccountMessage >> dispatch)
        | Overview          -> 
            match model.UserSession with
            | Some us -> Overview.overview us
            | None -> ()
    ]
