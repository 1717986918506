module ViewHelpers

open Feliz
open Feliz.Bulma

let errorMsg (errMsg : string) dismiss =
    Bulma.notification [
        color.isDanger
        prop.style [ style.padding (length.px 10) ]
        prop.children [
            Html.text errMsg
            Bulma.delete [
                prop.onClick dismiss
            ]
        ]
    ]

let modalBox  onClose (children : seq<ReactElement>) =
    Bulma.modal [
        Bulma.modal.isActive
        prop.children [
            Bulma.modalBackground [ prop.onClick onClose ]
            Bulma.modalContent [
                Bulma.box children
            ]
        ]
    ]


let loadingIndicatorSmall () =
    Bulma.button.button [
        prop.style [
            style.custom ("background", "none")
            style.custom ("border", "none")
        ]
        Bulma.button.isLoading
        Bulma.button.isSmall
    ]

let loadingIndicatorMedium () =
    Bulma.button.button [
        prop.style [
            style.custom ("background", "none")
            style.custom ("border", "none")
        ]
        Bulma.button.isLoading
        Bulma.button.isMedium
    ]

let loadingIndicatorLarge () =
    Bulma.button.button [
        prop.style [
            style.custom ("background", "none")
            style.custom ("border", "none")
        ]
        Bulma.button.isLoading
        Bulma.button.isLarge
    ]


let productImg prodId (size:float) =
    Html.div [
        prop.style [
            style.display.flex
            style.alignItems.center
            style.justifyContent.center
            style.width (length.rem size)
            style.height (length.rem size)
            style.margin.auto
            style.borderRadius (length.percent 50)
            style.custom ("border", "solid 3px #4d8398")
            style.overflowY.hidden
            style.overflowX.hidden
            style.backgroundImage (sprintf "url(%s)" (sprintf "/api/product/image/%i" prodId))
            style.custom ("background-position", "center center")
            style.backgroundSize.cover
        ]
    ]