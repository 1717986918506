module InventoryList

open ApiDataTypes
open Thoth.Fetch
open Feliz
open Feliz.Bulma

let private colorCodes = [|
    "#c70098"
    "#f5a207"
|]

type private CompareEntry = {
    Location    : Location.Location
    Inventory   : Product.Inventory [] option
    ColorCode   : string
}

type private Model = {
    Location            : Location.Location
    Inventory           : Product.Inventory [] option
    Filter              : string option
    ErrorMsg            : string option
    SelectedExp         : Product.Inventory option
    SelectedMin         : Product.Inventory option

    ComparableLocations : Location.Location [] option
    CompareForm         : bool
    Compare             : CompareEntry []
    ColorCodeCounter    : int
}

and private Message =
    | InventoryResponse     of Result<Product.Inventory [], FetchError>
    | ComparableResponse    of Result<Location.Location [], FetchError>
    | UpdateFilter          of string
    | DismissError
    | SelectExp             of Product.Inventory
    | SelectMin             of Product.Inventory
    | DismissSelected
    | ToggleCompareForm
    | SelectCompareLocation of Location.Location
    | RemoveCompareLocation of int
    | CompareResponse       of int * Result<Product.Inventory [], FetchError>

let private init x = {
    Location            = x
    Inventory           = None
    Filter              = None
    ErrorMsg            = None
    SelectedExp         = None
    SelectedMin         = None
    ComparableLocations = None
    CompareForm         = false
    Compare             = [||]
    ColorCodeCounter    = 0
}

let private update (model:Model) msg =
    match msg with
    | InventoryResponse res ->
        match res with
        | Ok x -> {model with Inventory = Some x}
        | Error err ->
            let errMsg =
                match err with
                | _ -> ( sprintf "Noe gikk galt. Kunne ikke laste inventar for %s." model.Location.Name)
            {model with ErrorMsg = Some errMsg}
    
    | ComparableResponse res ->
        match res with
        | Ok x -> {model with ComparableLocations = Some x}
        | Error err ->
            let errMsg =
                match err with
                | _ -> "Noe gikk galt. Kunne ikke laste sammenlignbare lager."
            {model with ErrorMsg = Some errMsg}

    | UpdateFilter x ->
        let y =
            match x with
            | null | "" -> None
            | z -> Some z
        {model with Filter = y}
    
    | DismissError -> {model with ErrorMsg = None}

    | SelectExp x -> {model with SelectedExp = Some x; SelectedMin = None}
    | SelectMin x -> {model with SelectedMin = Some x; SelectedExp = None}
    | DismissSelected -> {model with SelectedExp = None; SelectedMin = None}

    | ToggleCompareForm -> {model with CompareForm = not model.CompareForm}

    | SelectCompareLocation x -> 
        
        let cc =
            colorCodes.[model.ColorCodeCounter]
        let y =
            Array.append [| 
                {Location =x; Inventory = None; ColorCode = cc}|]
                model.Compare
            |> Array.distinctBy (fun z -> z.Location.Id)
        
        let newCC =
            model.ColorCodeCounter + 1
            |> function
                | c when c >= colorCodes.Length -> 0
                | c -> c
        {model with Compare = y; CompareForm = false; ColorCodeCounter = newCC}
    
    | RemoveCompareLocation locId ->
        let y =
            model.Compare
            |> Array.filter(fun z -> z.Location.Id <> locId )
        {model with Compare = y}
    
    | CompareResponse (locId, res ) ->
        match res with
        | Ok x ->
            let y =
                model.Compare
                |> Array.map(fun z ->
                    if z.Location.Id = locId then
                        {z with Inventory = Some x}
                    else z
                )
            {model with Compare = y}
        
        | Error err ->
            let errMsg =
                match err with
                | _ -> "Noe gikk galt. Kunne ikke laste lagerbeholdning for lager."
            {model with ErrorMsg = Some errMsg}


let private fetchInventory locId dispatch =
    promise {
        let requestPath =
            sprintf "/api/inventory/%i" locId
        let! res = Promises.tryGet<Product.Inventory []> requestPath
        dispatch (InventoryResponse res)
    }


let private fetchComparableLocations locId dispatch =
    promise {
        let requestPath = 
            sprintf "/api/location/comparable/%i" locId
        let! res = Promises.tryGet<Location.Location []> requestPath
        dispatch (ComparableResponse res)
    }

let private fetchCompareInventory locId dispatch =
    promise {
        let requestPath =
            sprintf "/api/inventory/%i" locId
        let! res = Promises.tryGet<Product.Inventory []> requestPath
        dispatch (CompareResponse (locId, res))
    }


let private drawExp (x : Product.Inventory) dispatch =
    Bulma.modal [
        Bulma.modal.isActive
        prop.children [
            Bulma.modalBackground[
                prop.style [style.backgroundColor "rgba(10,10,10,.4)"]
                prop.onClick (fun _ -> dispatch DismissSelected)]
            Bulma.card [
                Bulma.modalCardHead [
                    Bulma.modalCardTitle "Produkter utløper på dato"
                    Bulma.delete [
                        prop.onClick (fun _ -> dispatch DismissSelected)
                    ]
                ]
                Bulma.modalCardBody [
                    prop.style [
                        style.backgroundColor "#6bc0ae"
                        style.color "#fff"
                        style.textAlign.center
                        style.fontWeight.bold
                    ]
                    prop.children [
                        Html.h5 [
                            prop.style [style.color "#fff"]
                            prop.className "title is-5"
                            prop.text x.Product.Name
                        ]

                        Html.ul [
                            x.Expirations
                            |> Array.map(fun y ->
                                Html.li [
                                    prop.text (sprintf "%i stk utløper %s" y.Quantity (Utils.fromDateToLocalDate y.Expires))
                                ]
                            )
                            |> Fable.React.Helpers.ofArray
                        ]
                    ]
                ]
                Bulma.modalCardFoot [
                    Bulma.button.button [
                        Bulma.button.isSmall
                        prop.onClick (fun _ -> dispatch DismissSelected)
                        prop.text "Lukk"
                    ]
                ]
            ]
        ]
    ]

let private drawBelowMin (x : Product.Inventory) dispatch =
    Bulma.modal [
        Bulma.modal.isActive
        prop.children [
            Bulma.modalBackground[
                prop.style [style.backgroundColor "rgba(10,10,10,.4)"]
                prop.onClick (fun _ -> dispatch DismissSelected)]
            Bulma.card [
                Bulma.modalCardHead [
                    Bulma.modalCardTitle "Antall produkter under minimumsgrensen"
                    Bulma.delete [
                        prop.onClick (fun _ -> dispatch DismissSelected)
                    ]
                ]
                Bulma.modalCardBody [
                    prop.style [
                        style.backgroundColor "#6bc0ae"
                        style.color "#fff"
                        style.textAlign.center
                        style.fontWeight.bold
                    ]
                    prop.children [
                        Html.h5 [
                            prop.style [style.color "#fff"]
                            prop.className "title is-5"
                            prop.text x.Product.Name
                        ]

                        Html.p [
                            prop.text 
                                (sprintf "Lagerbeholdning ligger under minimumsbeholdningen på %i stk spesifisert for dette produktet." x.RequiredMinimum)
                        ]
                    ]
                ]
                Bulma.modalCardFoot [
                    Bulma.button.button [
                        Bulma.button.isSmall
                        prop.onClick (fun _ -> dispatch DismissSelected)
                        prop.text "Lukk"
                    ]
                ]
            ]
        ]
    ]


let private drawInventoryEntry (x: Product.Inventory) (y:CompareEntry []) dispatch =
    Html.tr [
        prop.className "product-row"
        prop.children [
            Html.td [
                prop.style [
                    style.width (length.rem 3.2)
                    style.padding (length.px 5)
                ]
                prop.children [
                    ViewHelpers.productImg x.Product.Id 3.1
                ]
            ]
            Html.td [
                Html.span [
                    prop.style [style.fontWeight.bold]
                    prop.text x.Product.Name
                ]
                Html.br []
                Html.span [
                    prop.style [
                        style.fontSize (length.rem 0.8)
                    ]   
                    prop.text (sprintf "Produktnummer: %s" x.Product.ProductNumber)
                ]
            ]
            Html.td [
                prop.style [style.fontStyle.italic; style.fontWeight.bold]
                prop.text (sprintf "%i stk" x.Quantity)
            ]
            Html.td [
                y
                |> Array.map(fun z ->
                    match z.Inventory with
                    | None -> Html.none
                    | Some i ->
                        i
                        |> Array.tryFind (fun e -> e.Product.Id = x.Product.Id)
                        |> function
                            | None -> Html.none
                            | Some e ->
                                Html.span [
                                    prop.style [
                                        style.fontStyle.italic
                                        style.fontWeight.bold
                                        style.color z.ColorCode
                                    ]
                                    prop.text (sprintf "%i stk" e.Quantity)
                                ]
                )
                |> Fable.React.Helpers.ofArray
            ]
            Html.td [
                if x.BelowMinimum then
                    Bulma.icon [
                        Bulma.icon.isMedium
                        prop.style [
                            style.cursor.pointer
                        ]
                        prop.onClick (fun _ -> dispatch (SelectMin x))
                        prop.children [
                            Html.i [
                                prop.className "fas fa-arrow-alt-circle-down fa-lg"
                            ]
                        ]
                    ]
                
                if x.Expirations.Length > 0 then
                    Bulma.icon [
                        Bulma.icon.isMedium
                        prop.style [
                            style.cursor.pointer
                        ]
                        prop.onClick (fun _ -> dispatch (SelectExp x))
                        prop.children [
                            Html.i [
                                prop.className "fas fa-clock fa-lg"
                            ]
                        ]
                    ]

            ]
        ]
    ]


let private drawInventory model dispatch (x : Product.Inventory []) =
    let y =
        match model.Filter with
        | None -> x
        | Some f ->
            let lowered = f.ToLower()
            x
            |> Array.filter(fun z -> 
                z.Product.Name.ToLower().Contains(lowered) ||
                z.Product.ProductNumber.ToLower().Contains(lowered)
            )
    Bulma.table [
        Bulma.table.isFullWidth
        prop.children [
            Html.thead [
                prop.style [style.fontSize (length.rem 0.8)]
                prop.children [
                    Html.th []
                    Html.th "Produkt"
                    Html.th "Antall"
                    Html.th []
                    Html.th "Merknad"
                ]
            ]
            Html.tbody [
                y
                |> Array.map(fun y -> drawInventoryEntry y model.Compare dispatch)
                |> Fable.React.Helpers.ofArray
            ]
        ]
    ]


let private drawComparison model dispatch =
    Html.div [
        prop.style [
            style.marginTop (length.px 20)
        ]
        prop.children [
            Html.div [
                prop.style [
                    style.display.flex
                    style.alignItems.center
                ]
                prop.children [
                    Html.h5 [
                        prop.style [
                            style.color "#fff"
                            style.marginBottom (length.px 3)
                            style.marginLeft (length.px 15)
                            style.marginRight (length.px 10)
                        ]
                        prop.className "title is-5"
                        prop.text "Sammenlign lager"
                    ]

                    if not model.CompareForm then
                        Bulma.button.button [
                            prop.className "icon-button"
                            Bulma.button.isSmall
                            prop.onClick (fun _ -> dispatch ToggleCompareForm)
                            prop.children [Html.i [prop.className "fas fa-plus"]]
                        ]
                    else
                        match model.ComparableLocations with
                        | None -> ViewHelpers.loadingIndicatorSmall ()
                        | Some x ->
                            let selectable =
                                x
                                |> Array.filter(fun y -> 
                                    model.Compare
                                    |> Array.exists(fun z -> z.Location.Id = y.Id)
                                    |> not
                                )
                            
                            if selectable.Length > 0 then
                                Bulma.select [
                                    Bulma.select.isSmall
                                    prop.value -1

                                    prop.onChange (fun (y:string) ->
                                        try
                                            let asInt = int y
                                            selectable
                                            |> Array.tryFind(fun z -> z.Id = asInt)
                                            |> function
                                                | None -> ()
                                                | Some z ->
                                                    dispatch (SelectCompareLocation z)
                                                    fetchCompareInventory z.Id dispatch
                                                    |> Promise.start
                                        with | _ -> ()
                                    )
                                    prop.children [
                                        Html.option [
                                            prop.value -1
                                            prop.hidden true
                                            prop.text "Velg lager"
                                        ]

                                        selectable
                                        |> Array.map(fun y -> Html.option [prop.value y.Id; prop.text y.Name])
                                        |> Fable.React.Helpers.ofArray
                                    ]
                                ]
                            else
                                Html.span [
                                    prop.style [
                                        style.color "#fff"
                                        style.fontSize (length.rem 0.8)
                                    ]
                                    prop.text "Ingen lager å velge mellom."
                                ]

                            Bulma.button.button [
                                prop.className "icon-button"
                                Bulma.button.isSmall
                                prop.style [style.marginLeft (length.px 10)]
                                prop.onClick (fun _ -> dispatch ToggleCompareForm)
                                prop.children [Html.i [prop.className "fas fa-times"]]
                            ]
                ]
            
            ]

            Bulma.table [
                prop.style [style.marginBottom 0]
                prop.children [
                    Html.thead []
                    Html.tbody [
                        model.Compare
                        |> Array.map(fun y ->
                            Html.tr [
                                prop.className "notification-row"
                                prop.children [
                                    Html.td [
                                        prop.style [
                                            style.display.flex
                                            style.alignItems.center
                                        ]
                                        prop.children [
                                            Bulma.icon [
                                                prop.children [
                                                    Html.i [
                                                        prop.style [
                                                            style.color y.ColorCode
                                                            style.marginRight (length.px 5)
                                                        ]
                                                        prop.className "fas fa-circle"
                                                    ]
                                                ]
                                            ]
                                            Html.span [
                                                prop.style [style.marginRight (length.px 5)]
                                                prop.text y.Location.Name
                                            ]

                                            Bulma.button.button [
                                                prop.className "icon-button"
                                                Bulma.button.isSmall
                                                prop.onClick (fun _ -> dispatch (RemoveCompareLocation y.Location.Id))
                                                prop.children [Html.i [prop.className "fas fa-times"]]
                                            ]
                                        ]
                                    ]
                                    Html.td [
                                        if y.Inventory.IsNone then
                                            ViewHelpers.loadingIndicatorSmall ()
                                    ]
                                ]
                            ]
                        )
                        |> Fable.React.Helpers.ofArray
                    ]
                ]
            ]
        ]
    ]

let private view model dispatch =
    Html.div [

        prop.children [
            Html.div [
                prop.style [
                    style.textAlign.right
                ]
                prop.children [
                    Html.span [
                        prop.style [style.color "#fff"; style.fontWeight.bold]
                        prop.text "Lagerbeholdning"
                    ]
                ]
            ]
            Html.div [
                prop.style [
                    style.custom ("border", "solid 1px #4d8398")
                    style.borderRadius 5
                    style.padding (length.vw 1)
                    style.backgroundColor "#e0f1f1"
                    style.overflowY.scroll
                    style.maxHeight (length.vh 70)
                ]
                prop.children [
                    match model.ErrorMsg with
                    | None -> ()
                    | Some err -> ViewHelpers.errorMsg err (fun _ -> dispatch DismissError)
                    match model.Inventory with
                    | None -> ViewHelpers.loadingIndicatorSmall ()
                    | Some x ->

                        Bulma.field.div [
                            Bulma.control.div [
                                Bulma.control.hasIconsLeft
                                prop.children [
                                    Bulma.input.text [
                                        prop.defaultValue (
                                            match model.Filter with
                                            | None -> ""
                                            | Some f -> f
                                        )
                                        prop.placeholder "Søk blandt produkter .."
                                        prop.onChange (UpdateFilter >> dispatch)
                                        prop.style [style.marginBottom (length.px 5)]
                                    ]

                                    Bulma.icon [
                                        Bulma.icon.isLeft
                                        prop.children [Html.i [prop.className "fas fa-search"]]
                                    ]
                                ]
                            ]
                        ]
                        drawInventory model dispatch x
                        match model.SelectedExp with
                        | None -> ()
                        | Some exp -> drawExp exp dispatch

                        match model.SelectedMin with
                        | None -> ()
                        | Some m -> drawBelowMin m dispatch
                ]
            ]

            drawComparison model dispatch
        ]
    ]


let inventoryList location =
    React.functionComponent ("inventory-list", (fun (props : {|loc : Location.Location|}) -> 
        let initialModel = init props.loc
        let model, dispatch = React.useReducer(update, initialModel)

        React.useEffect((fun _ ->
            fetchInventory props.loc.Id dispatch
            |> Promise.start
            fetchComparableLocations props.loc.Id dispatch
            |> Promise.start
        ), [||])

        view model dispatch
    )) {| loc = location|}