module Login

open Feliz
open Feliz.Bulma
open Elmish
open ApiDataTypes

type FetchError = Thoth.Fetch.FetchError
type GenericResult = Result<GenericMessage, FetchError>

let locale = Locale.Locale

module PasswordRecovery =
    open Fable.Reaction
    open FSharp.Control

    type private Model = {
        Email       : string
        ErrorMsg    : string option
        Processing  : bool
        Sent        : bool
        OnClose     : unit -> unit
    }

    type private Message =
        | UpdateEmail of string
        | Commit of string
        | CommitResponse of GenericResult
        | DismissError

    let private init onClose = {
        Email = ""
        ErrorMsg = None
        Processing = false
        Sent = false
        OnClose = onClose
        }

    let private handleDefault model =
        { model with
            ErrorMsg = Some locale.login.reseterror
            Processing = false
        }

    let private handleCommit model (x : string) =
        if x.Length > 0 then
            { model with
                Processing = true
                ErrorMsg = None
            }
        else
            { model with
                ErrorMsg = Some locale.fields.``invalid-email``
            }

    let private handleCommitResponse model (res : GenericResult) =
        match res with
        | Ok x ->
            if x.Result = Shared.SuccessIndicator then
                { model with Sent = true }
            else
                { model with
                    ErrorMsg = Some x.Message
                    Processing = false
                }
        | _ -> handleDefault model

    let private update (model : Model) msg =
        match msg with
        | UpdateEmail x      -> { model with Email = x }
        | Commit x           -> handleCommit model x
        | CommitResponse res -> handleCommitResponse model res
        | DismissError       -> { model with ErrorMsg = None }

    let private requestReset email =
        let p = Promises.tryPost<string, ApiDataTypes.GenericMessage> "/api/user/initpasswordreset" email
        AsyncRx.ofPromise p |> AsyncRx.map(CommitResponse)

    let private stream model msgs =
        let commit =
            msgs
            |> AsyncRx.choose (
                function
                | Commit x when x.Length > 0 -> Some x
                | _ -> None)
            |> AsyncRx.flatMap requestReset
        AsyncRx.mergeSeq [ msgs; commit ] |> AsyncRx.tag "msgs"

    let private view model dispatch =
        let content' =
            Bulma.box [
                if not model.Sent then
                    Bulma.field.div [
                        Bulma.label locale.fields.email
                        Bulma.input.email [
                            prop.placeholder "eksempel@eksempel.no"
                            prop.defaultValue model.Email
                            prop.onChange(UpdateEmail >> dispatch)
                        ]
                    ]
                    Bulma.button.button [
                        prop.style [ style.marginBottom (length.px 10) ]
                        Bulma.color.isInfo
                        if model.Processing then Bulma.button.isLoading
                        prop.onClick(fun _ ->
                            if not model.Processing then
                                dispatch (Commit model.Email)
                        )
                        prop.text locale.login.reset
                    ]
                    match model.ErrorMsg with
                    | None -> ()
                    | Some errMsg ->
                        ViewHelpers.errorMsg errMsg (fun _ ->
                            dispatch DismissError)
                else
                    Html.div [
                        prop.style [
                            style.display.flex
                            style.justifyContent.center
                            style.alignItems.center
                            style.minHeight (length.px 50)
                            style.flexDirection.column
                            style.textAlign.center
                        ]
                        prop.children [
                            Html.p [ Html.text locale.login.resetsent ]
                            Bulma.button.button [
                                prop.onClick(fun _ -> model.OnClose ())
                                prop.style [ style.marginTop (length.px 20) ]
                                prop.children [
                                    Bulma.icon [
                                        Bulma.icon.isLeft
                                        Bulma.icon.isSmall
                                        prop.children [
                                            Html.i [
                                                prop.className "fas fa-check"
                                            ]
                                        ]
                                    ]
                                    Html.span [ Html.text "Ok" ]
                                ]
                            ]
                        ]
                    ]
            ]
        Bulma.modal [
            Bulma.modal.isActive
            prop.children [
                Bulma.modalBackground [ prop.onClick(fun _ -> model.OnClose ())]
                Bulma.modalContent [
                    prop.style [ style.maxWidth (length.px 350) ]
                    prop.children [ content' ]
                ]
            ]
        ]

    let passwordRecovery x =
        React.functionComponent("PasswordRecovery", fun (props : {| close : unit -> unit |}) ->
            let initialModel = init props.close
            let model = Fable.React.HookBindings.Hooks.useReducer (update , initialModel)
            let dispatch, _ =
                Reaction.useStatefulStream(model.current, model.update, stream)
            view model.current dispatch
        ) x


type LoginStep =
    | User
    | TFA

type Model = {
    Step            : LoginStep
    Email           : string
    Password        : string
    TFAToken        : string
    RememberMe      : bool
    FocusPassword   : bool
    ErrorMsg        : string option
    Processing      : bool
    DisplayRecovery : bool
}

type Message =
    | UpdateEmail       of string
    | UpdatePassword    of string
    | UpdateTFAToken    of string
    | ToggleRememberMe
    | DismissError
    | TryLogin
    | LoginResponse     of Result<LoginResponse, FetchError>
    | Success           of UserSession
    | ToggleRecovery

let init () =
    let rememberMe = LocalStorage.tryGetRememberMe ()
    {
        Step            = User
        Email           = match rememberMe with | Ok x -> x | _ -> ""
        Password        = ""
        TFAToken        = ""
        RememberMe      = match rememberMe with | Ok _ -> true | _ -> false
        FocusPassword   = match rememberMe with | Ok _ -> true | _ -> false
        ErrorMsg        = None
        Processing      = false
        DisplayRecovery = false
    }, Cmd.none

type private LoginData = string * string * string

let private handleUser model =
    if model.Email.Length > 0 && model.Password.Length > 0 then
        let tfaCookie =
            match LocalStorage.tryGetTFACookie () with
            | Ok x -> x
            | _ -> ""
        if model.RememberMe then LocalStorage.storeRememberMe model.Email
        let p () =
            Promises.tryPost<LoginData, LoginResponse>
                "/api/auth/signin" (model.Email, model.Password, tfaCookie)
        let cmd = Cmd.OfPromise.perform p () LoginResponse
        { model with
            Processing = true
            ErrorMsg = None
        }, cmd
    else
        { model with
            ErrorMsg = Some locale.fields.``invalid-username``
        }, Cmd.none

let private handleTFA model =
    if model.TFAToken.Length > 0 then
        let p () =
            Promises.tryPost<LoginData, LoginResponse>
                "/api/auth/tfa/signin"
                (model.Email, model.Password, model.TFAToken)
        let cmd = Cmd.OfPromise.perform p () LoginResponse
        { model with Processing = true; ErrorMsg = None }, cmd
    else
        { model with
            ErrorMsg = Some locale.fields.``invalid-code``
        }, Cmd.none

let private handleLoginRespnese model (res : Result<LoginResponse, FetchError>) =
    match res with
    | Error _ ->
        { model with
            Processing = false
            ErrorMsg = Some locale.login.loginerror
        }, Cmd.none
    | Ok x ->
        match x.Result with
        | Shared.SuccessIndicator ->
            if model.Step = TFA && x.Message.Length > 0 then
                LocalStorage.storeTFACookie x.Message
            LocalStorage.storeSession x.Session
            model, Cmd.ofMsg (Success x.Session)
        | Shared.ProgressIndicator ->
            { model with
                Step = TFA
                Processing = false
                ErrorMsg = None
            }, Cmd.none
        | _ ->
            { model with
                Processing = false
                ErrorMsg = Some x.Message
            }, Cmd.none

let update msg (model : Model) =
    match msg with
    | UpdateEmail x     -> { model with Email    = x }, Cmd.none
    | UpdatePassword x  -> { model with Password = x }, Cmd.none
    | UpdateTFAToken x  -> { model with TFAToken = x }, Cmd.none
    | ToggleRememberMe  ->
        if model.RememberMe then LocalStorage.deleteRememberMe()
        { model with RememberMe = not model.RememberMe }, Cmd.none
    | DismissError      -> { model with ErrorMsg    = None }, Cmd.none
    | TryLogin ->
        match model.Step with
        | User -> handleUser model
        | TFA -> handleTFA model
    | LoginResponse res -> handleLoginRespnese model res
    | ToggleRecovery -> { model with DisplayRecovery = not model.DisplayRecovery }, Cmd.none
    | _ -> model, Cmd.none

let private userField model dispatch =
    Bulma.field.div [
        Bulma.label locale.login.username
        Bulma.control.div [
            control.hasIconsLeft
            prop.children [
                Bulma.input.text [
                    if not model.FocusPassword then prop.autoFocus true
                    prop.placeholder locale.login.username
                    prop.defaultValue model.Email
                    prop.required true
                    prop.onChange (UpdateEmail >> dispatch)
                    prop.id "loginpage-user"
                    prop.onKeyDown(fun kc ->
                        if kc.key = "Enter" then
                            Utils.tryFocus "loginpage-password"
                    )
                ]
                Bulma.icon [
                    icon.isSmall
                    icon.isLeft
                    prop.children [
                        Html.i [ prop.className "fa fa-user" ]
                    ]
               ]
            ]
        ]
    ]

let private passwordField model dispatch =
    Bulma.field.div [
        Bulma.label locale.fields.password
        Bulma.control.div [
            control.hasIconsLeft
            prop.children [
                Bulma.input.password [
                    if model.FocusPassword then prop.autoFocus true
                    prop.placeholder locale.fields.password
                    prop.required true
                    prop.onChange (UpdatePassword >> dispatch)
                    prop.onKeyDown(fun kc ->
                        if kc.key = "Enter" then dispatch TryLogin
                    )
                    prop.id "loginpage-password"
                ]
                Bulma.icon [
                    icon.isSmall
                    icon.isLeft
                    prop.children [
                        Html.i [ prop.className "fa fa-lock" ]
                    ]
                ]
            ]
        ]
    ]

let private tfaField model dispatch =
    Bulma.field.div [
        Bulma.label locale.login.receivedcode
        Bulma.control.div [
            control.hasIconsLeft
            prop.children [
                Bulma.input.text [
                    if model.FocusPassword then prop.autoFocus true
                    prop.placeholder locale.login.code
                    prop.required true
                    prop.onChange (UpdateTFAToken >> dispatch)
                    prop.onKeyDown(fun kc ->
                        if kc.key = "Enter" then dispatch TryLogin
                    )
                    prop.id "loginpage-tfa"
                    prop.value model.TFAToken
                ]
                Bulma.icon [
                    icon.isSmall
                    icon.isLeft
                    prop.children [
                        Html.i [ prop.className "fa fa-lock" ]
                    ]
                ]
            ]
        ]
    ]

let private loginField model dispatch =
    let active = model.Email.Length > 0 && model.Password.Length >= 0
    Bulma.field.div [
        Bulma.button.a [
            if model.Processing then Bulma.button.isLoading
            prop.disabled (not active)
            prop.text locale.login.submit
            prop.onClick (fun _ -> dispatch TryLogin)
            prop.style [ style.marginTop (length.px 20) ]
            color.isPrimary
            prop.id "loginpage-login"
        ]
    ]

let private rememberMeField model dispatch =
    Bulma.field.div [
        Bulma.label [
            Bulma.input.checkbox [
                prop.style[ style.marginRight (length.px 5) ]
                prop.isChecked model.RememberMe
                prop.onChange(fun (_ : bool) -> dispatch ToggleRememberMe)
            ]
            Html.text locale.login.rememberme
        ]
    ]

let private loginForm (model : Model) dispatch =
    Bulma.box [
        (*Html.img [
            prop.src "/img/logo.png"
            prop.style [ style.marginBottom (length.px 30) ]

        ]*)
        userField model dispatch
        passwordField model dispatch
        rememberMeField model dispatch
        (*Html.a [
            prop.onClick(fun _ -> dispatch ToggleRecovery)
            prop.style [
                style.fontSize (length.rem 0.8)
                style.fontStyle.italic
            ]
            prop.children [ Html.text locale.login.forgotpwd ]
        ]*)
        if model.DisplayRecovery then
            PasswordRecovery.passwordRecovery
                {| close = (fun _ -> dispatch ToggleRecovery) |}
        loginField model dispatch
        match model.ErrorMsg with
        | None -> ()
        | Some errMsg ->
            ViewHelpers.errorMsg errMsg (fun _ -> dispatch DismissError)
    ]


let tfaForm model dispatch =
    Bulma.box [
        Html.img [
            prop.src "/img/logo.png"
            prop.style [ style.marginBottom (length.px 30) ]
        ]
        tfaField model dispatch
        loginField model dispatch
        match model.ErrorMsg with
        | None -> ()
        | Some errMsg ->
            ViewHelpers.errorMsg errMsg (fun _ -> dispatch DismissError)
    ]

let view model dispatch =
    Bulma.hero [
        hero.isFullHeight
        color.isLight
        prop.children [ Bulma.heroBody [ Bulma.container [
            Bulma.columns [
                columns.isCentered
                prop.children [
                    Bulma.column [
                        column.isOneThird
                        prop.children [
                            match model.Step with
                            | User -> loginForm model dispatch
                            | TFA -> tfaForm model dispatch
                        ]
                    ]
                ]
            ]
        ] ] ]
    ]
