module Account

open Elmish
open Feliz
open Fable.Reaction
open FSharp.Control
open Feliz.Bulma
open Thoth.Fetch
open ApiDataTypes

type FetchError = Thoth.Fetch.FetchError
type GenericResult = Result<GenericMessage, FetchError>

let locale = Locale.Locale

module UpdatePasswordForm =
    open FSharp.Control
    open Fable.Reaction

    type private Model = {
        User            : User.User
        OldPassword     : string
        NewPassword     : string
        RepeatPassword  : string
        OnClose         : unit -> unit
        Processing      : bool
        ErrorMsg        : string option
        Completed       : bool
    }

    type private Message =
        | UpdateOld         of string
        | UpdateNew         of string
        | UpdateRepeat      of string
        | Commit            of string * string * string
        | CommitResponse    of GenericResult
        | DismissError

    let private init x y =
        {
            User            = x
            OldPassword     = ""
            NewPassword     = ""
            RepeatPassword  = ""
            OnClose         = y
            Processing      = false
            ErrorMsg        = None
            Completed       = false
        }

    let private update model msg =
        match msg with
        | UpdateOld x -> { model with OldPassword = x }
        | UpdateNew x -> { model with NewPassword = x }
        | UpdateRepeat x -> { model with RepeatPassword = x }
        | Commit (o, n, r) ->
            if o.Length > 0 && n.Length > 0 && n = r then
                { model with Processing = true}
            elif o.Length = 0 then
                { model with
                    ErrorMsg = Some locale.account.``old-pwd-missing`` }
            elif n.Length = 0 then
                { model with
                    ErrorMsg = Some locale.account.``new-pwd-missing`` }
            else
                { model with
                    ErrorMsg = Some locale.account.``pwd-does-not-match`` }
        | CommitResponse res ->
            match res with
            | Ok x ->
                if x.Result = Shared.SuccessIndicator then
                    { model with Completed = true}
                else
                    { model with Processing = false; ErrorMsg = Some x.Message }
            | _ ->
                { model with
                    Processing = false
                    ErrorMsg = Some locale.account.``pwd-update-error``
                }
        | DismissError -> { model with ErrorMsg = None }

    let private commitNewPassword oldP newP =
        let p =
            Promises.tryPost<string * string, GenericMessage>
                "/api/user/changepassword" (oldP, newP)
        AsyncRx.ofPromise p
        |> AsyncRx.map CommitResponse

    let private stream model msgs =
        let commit =
            msgs
            |> AsyncRx.choose (
                function
                | Commit (o, n, r) ->
                    if o.Length > 0 && n.Length > 0 && n = r then Some (o, n)
                    else None
                | _ -> None
            )
            |> AsyncRx.flatMap(fun (oldP, newP) -> commitNewPassword oldP newP)
        AsyncRx.mergeSeq [
            msgs
            commit
        ] |> AsyncRx.tag "msgs"

    let private view model dispatch =
        let completed =
            Html.div [
                prop.style [
                    style.width (length.percent 100)
                    style.textAlign.center
                ]
                prop.children [
                    Html.h5 [ Html.text locale.account.``pwd-updated`` ]
                    Bulma.button.button [
                        prop.onClick (fun _ -> model.OnClose())
                        prop.children [
                            Bulma.icon [
                                Bulma.icon.isSmall
                                Bulma.icon.isLeft
                                prop.children [
                                    Html.i [ prop.className "fas fa-check" ]
                                ]
                            ]
                            Html.span [ Html.text "Ok"]
                        ]
                    ]
                ]
            ]
        let notCompleted = [
            Bulma.field.div [
                Bulma.label locale.account.``old-pwd``
                Bulma.input.password [
                    prop.autoFocus true
                    prop.onChange(UpdateOld >> dispatch)
                    prop.placeholder locale.account.``old-pwd``
                ]
            ]
            Bulma.field.div [
                Bulma.label locale.account.``new-pwd``
                Bulma.input.password [
                    prop.onChange(UpdateNew >> dispatch)
                    prop.placeholder locale.account.``new-pwd``
                ]
            ]
            Bulma.field.div [
                Bulma.label locale.account.``repeat-pwd``
                Bulma.input.password [
                    prop.onChange(UpdateRepeat >> dispatch)
                    prop.placeholder locale.account.``new-pwd``
                ]
            ]
            Bulma.button.button [
                Bulma.button.isSmall
                if model.Processing then Bulma.button.isLoading
                prop.style [
                    style.marginBottom (length.px 10)
                    style.marginRight(length.px 10)
                ]
                prop.onClick(fun _ ->
                    if not model.Processing then
                        dispatch (Commit
                            (
                            model.OldPassword,
                            model.NewPassword,
                            model.RepeatPassword
                            )
                        )
                    )
                prop.children [
                    Bulma.icon [
                        Bulma.icon.isSmall
                        Bulma.icon.isLeft
                        prop.children [ Html.i [ prop.className "fas fa-sync" ] ]
                    ]
                    Html.span locale.fields.change
                ]
            ]
            Bulma.button.button [
                if model.Processing then Bulma.button.isLoading
                Bulma.button.isSmall
                prop.onClick(fun _ -> if not model.Processing then model.OnClose ())
                prop.children [
                    Html.text locale.fields.cancel
                ]
            ]
            match model.ErrorMsg with
            | None -> ()
            | Some errMsg ->
                ViewHelpers.errorMsg errMsg (fun _ -> dispatch DismissError)
        ]
        ViewHelpers.modalBox (fun _ -> model.OnClose ()) [
            if model.Completed then
                completed
            else
                notCompleted |> Html.div
        ]

    let changeForm x =
        React.functionComponent("PasswordForm",
            fun (props : {| user : User.User ; close : unit -> unit |}) ->
                let initialModel = init props.user props.close
                let model = Fable.React.HookBindings.Hooks.useReducer (update, initialModel)
                let dispatch, _ =
                    Reaction.useStatefulStream(model.current, model.update, stream)
                view model.current dispatch
            ) x

module UpdateTFAForm =
    type private Model = {
        Password    : string
        Updated     : bool
        SetTo       : bool
        Processing  : bool
        OnClose     : bool option -> unit
        ErrorMsg    : string option
    }

    type private Message =
        | UpdatePassword of string
        | Commit of string
        | UpdateResponse of GenericResult
        | DismissError

    let private init setTo onClose = {
        Password = ""
        Updated = false
        SetTo = setTo
        OnClose = onClose
        Processing = false
        ErrorMsg = None
    }

    let private handleCommit model (pw : string) =
        if pw.Length > 0 then
            { model with ErrorMsg = None; Processing = true }
        else
            { model with ErrorMsg = Some locale.fields.``invalid-pwd`` }

    let private handleUpdateresponse model (res : GenericResult) =
        match res with
        | Ok x ->
            if x.Result = Shared.SuccessIndicator then
                { model with Updated = true; Processing = false }
            else
                { model with ErrorMsg = Some x.Message; Processing = false }
        | Error x ->
            Utils.checkError x
            { model with
                ErrorMsg = Some locale.account.``tfa-error``
                Processing = false }

    let private update model msg =
        match msg with
        | UpdatePassword x -> { model with Password = x }
        | Commit pw -> handleCommit model pw
        | UpdateResponse res -> handleUpdateresponse model res
        | DismissError -> { model with ErrorMsg = None }

    let private updateTFA (pw, setTo) =
        let requestPath =
            sprintf "/api/auth/tfa/%s" (if setTo then "enable" else "disable")
        let p = Promises.tryPost<string, GenericMessage> requestPath pw
        AsyncRx.ofPromise p |> AsyncRx.map UpdateResponse

    let private stream model msgs =
        let commit =
            msgs
            |> AsyncRx.choose (
                function
                | Commit x when x.Length > 0 -> Some (x, model.SetTo)
                | _ -> None
                )
            |> AsyncRx.flatMap updateTFA
        AsyncRx.mergeSeq [ msgs; commit ] |> AsyncRx.tag "msgs"

    let private view model dispatch =
        let passwd =
            Bulma.field.div [
                prop.style [ style.textAlign.center ]
                prop.children [
                    Bulma.label locale.fields.password
                    Bulma.input.password [
                        prop.onChange(UpdatePassword >> dispatch)
                        prop.onKeyDown(fun ke ->
                            if ke.key = "Enter" then
                                dispatch (Commit model.Password)
                        )
                        prop.autoFocus true
                        prop.placeholder locale.fields.password
                    ]
                ]
            ]
        let buttons =
            Html.div [
                Bulma.button.button [
                    Bulma.color.isInfo
                    if model.Processing then Bulma.button.isLoading
                    prop.onClick(fun _ ->
                        if not model.Processing then
                            dispatch (Commit model.Password))
                    prop.style [
                            style.marginBottom (length.px 10)
                            style.marginRight (length.px 10)
                    ]
                    prop.children [
                        Bulma.icon [
                            Bulma.icon.isLeft
                            prop.children [ Html.i [ prop.className "fas fa-sync" ] ]
                        ]
                        Html.span (
                            if model.SetTo then
                                locale.account.``enable-tfa``
                            else
                                locale.account.``disable-tfa``
                        )
                    ]
                ]
                Bulma.button.button [
                    if model.Processing then Bulma.button.isLoading
                    prop.onClick(fun _ ->
                        if not model.Processing then
                            model.OnClose None
                    )
                    prop.text locale.fields.cancel
                ]
            ]
        let notUpdated = [
            passwd
            buttons
            match model.ErrorMsg with
            | None -> ()
            | Some errMsg ->
                ViewHelpers.errorMsg errMsg (fun _ -> dispatch DismissError)
        ]
        let isUpdated = [
            Html.p (
                if model.SetTo then
                    locale.account.``tfa-activated``
                else
                    locale.account.``tfa-deactivated``
            )
            Bulma.button.button [
                prop.style [ style.marginTop (length.px 20) ]
                prop.onClick(fun _ -> model.OnClose (Some model.SetTo))
                prop.text locale.fields.close
            ]
        ]
        ViewHelpers.modalBox (fun _ -> model.OnClose None) [
            Html.div [
                prop.style [
                    style.display.flex
                    style.alignItems.center
                    style.justifyContent.center
                    style.flexDirection.column
                ]
                if model.Updated then
                    prop.children isUpdated
                else
                    prop.children notUpdated
            ]
        ]

    let tfaForm x =
        React.functionComponent ("TFAForm",
            fun (props : {| inUse : bool; close : bool option -> unit |}) ->
                let initialModel = init props.inUse props.close
                let model = Fable.React.HookBindings.Hooks.useReducer (update, initialModel)
                let dispatch, _ =
                    Reaction.useStatefulStream(model.current, model.update, stream)
                view model.current dispatch
            ) x

type Model = {
    UserSession         : UserSession
    User                : User.User option
    UsesTwoFactorAuth   : bool option
    DisplayPasswordForm : bool
    DisplayTFAForm      : bool
}

type Message =
    | UserResponse          of Result<User.User, FetchError>
    | TFAResponse           of Result<bool, FetchError>
    | TogglePasswordForm
    | ToggleTFAForm
    | CloseTFAForm          of bool option

let init userSession =
    let mdl = {
        UserSession         = userSession
        User                = None
        UsesTwoFactorAuth   = None
        DisplayPasswordForm = false
        DisplayTFAForm      = false
    }
    let p () = Promises.tryGet<User.User> "/api/user/loggedinuser"
    let t () = Promises.tryGet<bool> "/api/auth/tfa/enabled"
    let cmds = Cmd.batch [
        Cmd.OfPromise.perform p () UserResponse
        Cmd.OfPromise.perform t () TFAResponse
    ]
    mdl, cmds

let update msg model =
    match msg with
    | UserResponse res ->
        match res with
        | Ok usr -> { model with User = Some usr }, Cmd.none
        | Error x ->
            Utils.checkError x
            model, Cmd.none
    | TFAResponse res ->
        match res with
        | Ok x -> { model with UsesTwoFactorAuth = Some x }, Cmd.none
        | Error x ->
            Utils.checkError x
            model, Cmd.none
    | TogglePasswordForm ->
        { model with
            DisplayPasswordForm = not model.DisplayPasswordForm
        }, Cmd.none
    | ToggleTFAForm ->
        { model with
            DisplayTFAForm = not model.DisplayTFAForm
        }, Cmd.none
    | CloseTFAForm x ->
        match x with
        | Some newState ->
            { model with
                UsesTwoFactorAuth = Some newState
                DisplayTFAForm = false
            }, Cmd.none
        | None -> { model with DisplayTFAForm = false }, Cmd.none

let view model dispatch =
    let userTable (usr : User.User) =
        Bulma.table [
            Html.head []
            Html.tbody [
                Html.tr [
                    Html.th locale.fields.username
                    Html.td usr.Username
                ]
                Html.tr [
                    Html.th locale.fields.email
                    Html.td usr.Email
                ]
                Html.tr [
                    Html.th locale.fields.firstname
                    Html.td usr.Firstname
                ]
                Html.tr [
                    Html.th locale.fields.lastname
                    Html.td usr.Lastname
                ]
            ]
        ]
    let lockButton =
        Bulma.button.button [
            Bulma.color.isInfo
            prop.onClick(fun _ -> dispatch TogglePasswordForm)
            prop.children [
                Bulma.icon [
                    Bulma.icon.isLeft
                    prop.children [ Html.i [ prop.className "fas fa-lock" ] ]
                ]
                Html.span [ Html.text locale.account.``change-pwd`` ]
            ]
        ]
    let tfaToggle x =
        Bulma.field.div [
            Bulma.button.button [
                Bulma.color.isInfo
                prop.onClick(fun _ -> dispatch ToggleTFAForm)
                prop.children [
                    Bulma.icon [
                        Bulma.icon.isLeft
                        prop.children [ Html.i [
                            if x then "fas fa-check"
                            else "fas fa-times"
                            |> prop.className
                        ] ]
                    ]
                    Html.span [
                        if x then
                            locale.account.``tfa-enabled``
                        else
                            locale.account.``tfa-disabled``
                        |> Html.text
                    ]
                ]
            ]
            Html.p [
                prop.style [
                    style.fontSize (length.rem 0.8)
                    style.fontStyle.italic
                ]
                prop.children [
                    Html.text locale.account.``tfa-desc``
                ]
            ]
        ]
    let accountDropdown update state = [
        Bulma.dropdownTrigger [
            Bulma.button.button [
                Bulma.color.isInfo
                prop.onClick(fun _ -> update(not state ))
                prop.children [
                    Html.span locale.language
                    Html.span [
                        prop.className "icon is-small"
                        prop.children [
                            Html.i [
                                prop.className "fas fa-angle-down"
                                prop.ariaHidden true
                            ]
                        ]
                    ]
                ]
            ]
        ]
        Bulma.dropdownMenu [
            prop.id "dropdown-menu"
            prop.children [
                Bulma.dropdownContent [
                    Html.a [
                        prop.className "dropdown-item"
                        prop.onClick(fun _ ->
                            if Locale.CurrentLocale <> Locale.NO then
                                Locale.setLanguage Locale.Norwegian
                            else
                                update(not state) )
                        prop.text "Norsk bokmål"
                    ]
                    Html.a [
                        prop.className "dropdown-item"
                        prop.onClick(fun _ ->
                            if Locale.CurrentLocale <> Locale.EN then
                                Locale.setLanguage Locale.English
                            else
                                update(not state) )
                        prop.text "English"
                    ]
                ]
            ]
        ]
    ]
    Bulma.column [
        Bulma.column.isHalf
        Bulma.column.isOffsetOneQuarter
        prop.onKeyUp(fun ke ->
            if ke.key = "Escape" then
                if model.DisplayPasswordForm then dispatch TogglePasswordForm
                if model.DisplayTFAForm then dispatch ToggleTFAForm)
        prop.children [
            Html.h3 [
                prop.className "title is-3"
                prop.text locale.account.account
            ]
            Html.br []
            match model.User with
            | None -> Bulma.button.button [ Bulma.button.isLoading ]
            | Some usr ->
                Html.div [ userTable usr ]
                Bulma.field.div [ lockButton ]
                if model.DisplayPasswordForm then
                    UpdatePasswordForm.changeForm
                        {|
                            user = usr
                            close = (fun _ -> dispatch TogglePasswordForm)
                        |}
                match model.UsesTwoFactorAuth with
                | None -> ()
                | Some x ->
                    tfaToggle x
                    if model.DisplayTFAForm then
                        UpdateTFAForm.tfaForm
                            {|
                                inUse = not x
                                close = (fun x -> dispatch (CloseTFAForm x))
                            |}

                React.functionComponent (fun _ ->
                    let (state, update) = React.useState(false)
                    Bulma.field.div [
                        Bulma.dropdown [
                            if state then
                                dropdown.isActive
                            prop.children (accountDropdown update state)
                        ]
                    ]
                ) ()
        ]
    ]

