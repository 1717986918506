module Utils

open Fable
open Fable.Core.JsInterop

let tryFocus elemId =
    try
        Browser.Dom.window.document.getElementById(elemId)?focus()
    with | _ -> ()


let checkError (x : Thoth.Fetch.FetchError) =
    match x with
    | Thoth.Fetch.FetchFailed y ->
        if y.Status = 401 then
            LocalStorage.deleteSession ()
            Browser.Dom.window.location.reload ()
    | _ -> ()

let fromUtcToLocalDate utcString = 
    try 
        System.DateTime.Parse( utcString ).ToString "dd/MM/yyyy"
    with 
    | _ -> utcString

let fromDateToLocalDate dateString =
    try
        System.DateTime.Parse dateString
        |> fun x -> x.ToString "dd/MM/yyyy"
    with
    | _ -> dateString