module Main

open Fable.Core.JsInterop

importAll "./public/style.scss"

open Elmish
open Elmish.React
open Elmish.Debug
open Elmish.HMR
open App

// App
Program.mkProgram init update render
|> Program.withReactSynchronous "feliz-app"
|> Program.run
