module Overview

open ApiDataTypes
open Thoth.Fetch
open Feliz
open Feliz.Bulma

type private View =
    | Start
    | Inventory
    | Control
    | ActiveOrders
    | Webshop
    | Reports
    | Settings
    | Support

type private Model = {
    Session             : UserSession
    CurrentView         : View
    Locations           : Location.Location [] option
    SelectedLocation    : Location.Location option
    ErrorMsg            : string option
}
and private Message =
    | LocationsResponse of Result<Location.Location [], FetchError>
    | SelectLocation    of int
    | DismissError
    | SelectView        of View

let private init session = {
    Session             = session
    CurrentView         = Start
    Locations           = None
    SelectedLocation    = None
    ErrorMsg            = None
}

let private update model msg =
    match msg with
    | LocationsResponse res ->
        match res with
        | Ok x -> 
            let selectedLocation =
                match model.SelectedLocation with
                | Some l -> Some l
                | None ->
                    x
                    |> Array.tryFind (fun y -> y.Id = model.Session.LocationId)
            {model with Locations = Some x; SelectedLocation = selectedLocation}
        | Error err ->
            let errMsg =
                match err with
                | _ -> "Noe gikk galt. Kunne ikke laste lager."
            {model with ErrorMsg = Some errMsg}
    
    | SelectLocation locId -> 
        match model.Locations with
        | Some x ->
            let y =
                x
                |> Array.tryFind (fun z -> z.Id = locId)
                |> function
                    | Some z -> Some z
                    | None -> model.SelectedLocation
            {model with SelectedLocation = y}

        | None -> model

    | DismissError -> {model with ErrorMsg = None}

    | SelectView x -> {model with CurrentView = x}

let private fetchLocations dispatch =
    promise {
        let requestPath = "/api/location/all"
        let! res = Promises.tryGet<Location.Location []> requestPath
        dispatch (LocationsResponse res)
    }


let private drawMenu model dispatch =
    let menuButton vw (title:string) =
        let isActive = vw = model.CurrentView
        Bulma.button.button [
            Bulma.button.isFullWidth
            prop.className (if isActive then "menu-button-active" else "menu-button")
            prop.onClick (fun _ -> dispatch (SelectView vw))
            prop.text title
        ]
    Html.div [
        prop.style [
            style.paddingLeft (length.vw 2)
            style.paddingRight (length.vw 2)
            style.display.flex
            style.flexDirection.column
            style.alignItems.center
        ]
        prop.children [

            Html.img [
                prop.src "/logo.png"
            ]
            menuButton
                Start
                "Startside"
            
            menuButton
                Inventory
                "Largerbeholdning"
            
            menuButton
                Control
                "Kontroll lager"
            
            menuButton
                ActiveOrders
                "Aktive bestillingsavtaler"
            
            menuButton
                Webshop
                "Nettbutikk/ekstrabestillinger"
            
            menuButton
                Reports
                "Rapporter"
            
            menuButton
                Settings
                "Innstillinger"
            
            menuButton
                Support
                "Support"
        ]
    ]

let private view model dispatch =
    Bulma.columns [
        Bulma.column [
            Bulma.column.isOneQuarter
            prop.children [
                drawMenu model dispatch
            ]
        ]
        Bulma.column [
            Bulma.column.isThreeQuarters
            prop.className "content-column"
            prop.children [
                match model.ErrorMsg with
                | None -> ()
                | Some errMsg -> ViewHelpers.errorMsg errMsg (fun _ -> dispatch DismissError)
                match model.Locations with
                | None ->
                    Html.div [
                        prop.style [
                            style.width (length.percent 100)
                            style.height (length.vh 80)
                            style.display.flex
                            style.justifyContent.center
                            style.alignItems.center
                        ]
                        prop.children [
                            ViewHelpers.loadingIndicatorLarge ()
                        ]
                    ]
                
                | Some x ->

                    Bulma.select [
                        Bulma.select.isLarge
                        prop.className "location-select"
                        prop.value (
                            match model.SelectedLocation with
                            | None -> -1
                            | Some sl -> sl.Id
                        )
                        prop.onChange (fun (x:string) ->
                            try
                                let asInt = int x
                                dispatch (SelectLocation asInt)
                            with | _ -> ()
                        )
                        prop.children [
                            Html.option [prop.hidden true; prop.value -1; prop.text "Ingen lager valgt."]

                            x
                            |> Array.map (fun l -> 
                                Html.option [prop.value l.Id; prop.text l.Name]
                            )
                            |> Fable.React.Helpers.ofArray
                        ] 
                    ]

                    match model.SelectedLocation with
                    | None -> ()
                    | Some l ->
                        match model.CurrentView with
                        | Start -> Startpage.startpage l
                        | Inventory ->
                            InventoryList.inventoryList l
                        
                        | Control ->
                            InventoryControl.inventoryControl model.Session l
                        | _ -> ()
            ]
        ]
    ]


let overview session =
    React.functionComponent ("overview", (fun ( props : {| session : UserSession |}) ->
        let initialModel = init props.session
        let model, dispatch = React.useReducer(update, initialModel)

        React.useEffect ((fun _ ->    
            fetchLocations dispatch
            |> Promise.start
        ),[||])
        view model dispatch
    )) {| session = session |}